import { types } from 'mobx-state-tree';

export const PourEvent = types.model({
  archived: types.maybeNull(types.boolean),
  bru_addr: types.maybeNull(types.integer),
  bru_barometric: types.maybeNull(types.number),
  bru_humidity: types.maybeNull(types.number),
  bru_temp_c: types.maybeNull(types.number),
  corrected_total_ml: types.maybeNull(types.number),
  gateway_id: types.maybeNull(types.string),
  id: types.maybeNull(types.integer),
  poured_at: types.maybeNull(types.string),
  received_at: types.maybeNull(types.string),
  rtc_ts: types.maybeNull(types.number),
  sensor_addr: types.maybeNull(types.integer),
  sensor_ma_zero: types.maybeNull(types.number),
  sensor_temp_c_start: types.maybeNull(types.number),
  sensor_temp_c_stop: types.maybeNull(types.number),
  sensor_total_ml: types.maybeNull(types.number),
  sensor_total_samples: types.maybeNull(types.number),
  ts_duration: types.maybeNull(types.number),
  ts_start: types.maybeNull(types.number),
  _item_id: types.maybeNull(types.number),
  _line_id: types.maybeNull(types.number),
  _tap_id: types.maybeNull(types.number),
});
