import React, { useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Select,
  styled,
} from '@mui/material';

import {
  Close as CloseIcon,
  Edit as EditIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { differenceInMinutes } from 'date-fns';

import { BRUGreyIcon, SensorGreyIcon } from 'assets/icons';

import { useStore, useToast } from 'hooks';
import { messages } from 'config/messages';

import Tabs from 'components/shared/Tabs';
import OnlineStatus from 'components/shared/OnlineStatus';
import GatewayIconComponent from 'components/shared/GatewayIcon';

import GeneralTab from './GeneralTab';
import ReplacementHistoryTab from './ReplacementHistoryTab';
import CalibrationTab from './CalibrationTab';
import EditGatewayLocation from './EditLocationModal';

const DiagnosticInformationDialog = ({ open, onClose, cooler = null, devices, type }) => {
  const { topologyManagementStore } = useStore();
  const { successToast, errorToast } = useToast();

  const [activeGateway, setActiveGateway] = useState(devices.gateway);
  const [activeBRU, setActiveBRU] = useState(devices.bru);
  const [activeSensor, setActiveSensor] = useState(devices.sensor);
  const [selectedDevice, setSelectedDevice] = useState(devices[type]);
  const [deviceType, setDeviceType] = useState(() => type);

  const [editLocationModal, setEditLocationModal] = useState(false);
  const [connectionType, setConnectionType] = useState(
    devices.gateway?.metadata?.network_connectivity_mode || null,
  );

  const dataset = useMemo(
    () => topologyManagementStore.getDeviceHistory(`${type}s`, selectedDevice.id),
    [selectedDevice.id, type, topologyManagementStore],
  );

  const handleChange = useCallback(
    (type, device) => {
      setDeviceType(type);
      setSelectedDevice(device);

      if (type === 'gateway') {
        topologyManagementStore.setSelectedGatewayId(device.id);

        setActiveGateway(device);
        setConnectionType(device.gateway?.metadata?.network_connectivity_mode || null);
        setActiveBRU(null);
        setActiveSensor(null);
      }

      if (type === 'bru') {
        const newGateway = cooler.gateways.find(g => g.id === device._gateways_id);

        setActiveGateway(newGateway);
        setConnectionType(newGateway?.metadata?.network_connectivity_mode || null);
        setActiveBRU(device);
        setActiveSensor(null);
      }

      if (type === 'sensor') {
        if (differenceInMinutes(new Date(), new Date(device.latest_heartbeat_received_at)) <= 15) {
          topologyManagementStore.fetchSensorDiagnosticsInfo({
            gateway_identifier: activeGateway?.identifier,
            gateway_bru_address: activeBRU?.gateway_bru_address,
            bru_sensor_address: device?.bru_sensor_address,
          });
        }

        setActiveSensor(device);
      }
    },
    [cooler, topologyManagementStore, activeGateway?.identifier, activeBRU?.gateway_bru_address],
  );

  const handleConnectionTypeChange = useCallback(
    async ({ target: { value } }) => {
      setConnectionType(value);
      const { id, metadata } = activeGateway;

      try {
        const response = await topologyManagementStore.patchGateway(id, {
          metadata: {
            ...metadata,
            network_connectivity_mode: value,
          },
        });
        setActiveGateway(response);
        successToast(messages.gateway.CONNECTION_TYPE_UPDATE_SUCCESS);
      } catch (error) {
        errorToast(messages.gateway.CONNECTION_TYPE_UPDATE_ERROR);
      }
    },
    [activeGateway, successToast, errorToast, topologyManagementStore],
  );

  const SELECTED_DEVICE = useMemo(() => {
    if (deviceType === 'gateway') {
      return {
        icon: (
          <GatewayIconComponent
            type_id={activeGateway?.type_id}
            styles={{
              width: '40px',
              height: '40px',
              marginRight: '16px',
            }}
          />
        ),
        title: (
          <Box className="flex">
            <Typography className="device_title">{activeGateway?._gateway_types_name}</Typography>
            <OnlineStatus
              gateway={activeGateway}
              iconStyles={{
                position: 'relative',
                right: 0,
                top: connectionType ? '-8px' : 0,
              }}
            />
          </Box>
        ),
        subtitle: (
          <Box className="subtitle_box flex">
            <Typography className="device_subtitle">Serial # - {activeGateway?.id}</Typography>
            <LocationOnIcon className="icon_16" />
            <Typography className="device_subtitle">
              {activeGateway?.metadata.installation_location || 'N/A'}
            </Typography>
            <EditIcon className="icon_16 pointer" onClick={() => setEditLocationModal(true)} />
          </Box>
        ),
      };
    }

    if (deviceType === 'bru') {
      return {
        icon: <BRUGreyIcon className="icon_40" />,
        title: (
          <>
            <Typography className="device_title">{`BRU #${activeBRU?.gateway_bru_address}`}</Typography>
          </>
        ),
        subtitle: (
          <Box className="subtitle_box flex">
            <Typography className="device_subtitle">{`Serial # - ${
              activeBRU?.sticker || activeBRU?.id
            }`}</Typography>
          </Box>
        ),
      };
    }

    if (deviceType === 'sensor') {
      return {
        icon: <SensorGreyIcon className="icon_40" />,
        title: (
          <Box className="flex">
            <Typography className="device_title">
              {`Sensor #${activeSensor?.bru_sensor_address}`}
            </Typography>
            <OnlineStatus
              date={activeSensor?.latest_heartbeat_received_at}
              iconStyles={{ position: 'static' }}
            />
          </Box>
        ),
        subtitle: (
          <Box className="subtitle_box flex">
            <Typography className="device_subtitle">{`Serial # - ${activeSensor?.id}`}</Typography>
          </Box>
        ),
      };
    }
  }, [deviceType, activeSensor, activeBRU, activeGateway, connectionType]);

  return (
    <StyledRootDialog p={1} open={open} onClose={onClose}>
      <DialogContent>
        <Box className="root_box">
          <Box className="scroll_wrapper">
            <Box className="navigation_box scroll_wrapper_child">
              <Box className="flex sticky">
                <Typography> HARDWARE LIST</Typography>
              </Box>
              {cooler.gateways.map((gateway, cooler_index) => (
                <Box className="gateway_wrapper" key={cooler_index}>
                  <Box
                    className={clsx('gateway_box std_box flex clickable', {
                      selected: !activeBRU && gateway.id === activeGateway?.id,
                      active: activeBRU && gateway?.brus.map(b => b.id).includes(activeBRU.id),
                    })}
                    onClick={() => handleChange('gateway', gateway)}
                    mb={1}
                  >
                    <Box className="flex">
                      <GatewayIconComponent
                        type_id={gateway.type_id}
                        styles={{
                          width: '40px',
                          height: '40px',
                          marginRight: '16px',
                        }}
                      />
                      <Box className="block full">
                        <Typography display="inline">{gateway?._gateway_types_name}</Typography>
                        <Box className="flex">
                          <Typography className="device_subtitle">
                            Serial # - {gateway?.id}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <OnlineStatus
                      gateway={gateway}
                      iconStyles={{
                        position: 'relative',
                        top: gateway.metadata.network_connectivity_mode ? '-8px' : '0px',
                      }}
                    />
                  </Box>
                  <Box className="bru_wrapper" ml={1}>
                    {gateway.brus.map((bru, bru_key) => (
                      <Box
                        key={bru_key}
                        className={clsx('bru_box std_box flex clickable', {
                          selected: !activeSensor && bru.id === activeBRU?.id,
                          active:
                            activeSensor && bru?.sensors.map(s => s.id).includes(activeSensor.id),
                        })}
                        mb={1}
                        onClick={() => handleChange('bru', bru)}
                      >
                        <Box className="flex">
                          <BRUGreyIcon className="icon_40" />
                          <Box className="block full">
                            <Typography display="inline">
                              {`BRU #${bru?.gateway_bru_address}`}
                            </Typography>
                            <Box className="flex">
                              <Typography className="device_subtitle">
                                {`Serial # - ${bru?.id}`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <OnlineStatus
                          date={bru?.latest_heartbeat_received_at}
                          iconStyles={{ position: 'static' }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Divider className="divider" />
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="content_box">
            <Box className="content_body_box">
              <Box className="content_header_box flex" p={3}>
                {SELECTED_DEVICE?.icon}
                <Box>
                  {SELECTED_DEVICE?.title}
                  {SELECTED_DEVICE?.subtitle}
                </Box>
                <Box
                  className={clsx('gateway_connection_type_box', {
                    hidden: deviceType !== 'gateway',
                  })}
                >
                  <Typography display="inline" mr={1} fontSize={14}>
                    Connection Type
                  </Typography>

                  <Select
                    className="select"
                    labelId="label"
                    value={connectionType}
                    label=""
                    displayEmpty
                    onChange={handleConnectionTypeChange}
                  >
                    <MenuItem value={'wifi'}>Wi-Fi</MenuItem>
                    <MenuItem value={'ethernet'}>Ethernet</MenuItem>
                    <MenuItem value={'cellular'}>Cellular</MenuItem>
                    <MenuItem value={null}>Unknown</MenuItem>
                  </Select>
                </Box>
                <Box
                  className={clsx('gateway_connection_type_box flex around', {
                    hidden: deviceType === 'gateway' || !activeBRU,
                  })}
                >
                  {activeBRU?.sensors
                    .sort((a, b) => a.bru_sensor_address - b.bru_sensor_address)
                    .map((sensor, sensor_index) => (
                      <Box
                        className={clsx('sensor_box clickable flex around', {
                          selected: sensor.id === activeSensor?.id,
                        })}
                        onClick={() => handleChange('sensor', sensor)}
                        key={sensor_index}
                      >
                        <Typography className="sensor_name">
                          {`Sensor #${sensor.bru_sensor_address}`}
                        </Typography>
                        <OnlineStatus
                          date={sensor?.latest_heartbeat_received_at}
                          iconStyles={{ position: 'static' }}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>

              <Box className="tabs_box">
                <Tabs
                  className="tabs"
                  isNativeView
                  tabs={[
                    {
                      label: 'General',
                      panel: (
                        <GeneralTab device={selectedDevice} type={deviceType} dataset={dataset} />
                      ),
                      disabled: false,
                    },
                    {
                      label: 'Replacement History',
                      panel: <ReplacementHistoryTab />,
                      disabled: true,
                    },
                    {
                      label: 'Calibration',
                      panel: <CalibrationTab />,
                      disabled: true,
                    },
                  ]}
                />
              </Box>
            </Box>
            <IconButton className="close_sensor_btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      {editLocationModal && (
        <EditGatewayLocation
          open={editLocationModal}
          onClose={() => setEditLocationModal(false)}
          onSuccess={setActiveGateway}
          gateway={activeGateway}
        />
      )}
    </StyledRootDialog>
  );
};

export default observer(DiagnosticInformationDialog);

const StyledRootDialog = styled(Dialog)(({ theme: { palette } }) => ({
  borderRadius: '8px',
  zIndex: '1201 !important',

  '.MuiPaper-root': {
    maxWidth: 'none',
    position: 'absolute',
    top: 0,
    boxShadow: 'none',
  },
  '.MuiDialogContent-root': {
    backgroundColor: palette.secondary.greyLight,
    padding: '0 !important',
  },

  '.scroll_wrapper': {
    padding: '0 !important',
    height: '100%',
    width: '300px',
    overflow: 'hidden',
  },

  '.scroll_wrapper_child': {
    padding: 0,
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',

    paddingRight: '17px',
    boxSizing: 'content-box',
  },

  '.navigation_box': {
    height: '680px',
    width: '280px',
    margin: '16px 0 16px 16px',
  },

  '.clickable': {
    cursor: 'pointer',
  },
  '.hidden': {
    display: 'none !important',
  },

  '.root_box': {
    height: '680px',
    width: '1312px',
    display: 'flex',
  },

  '.content_box': {
    width: '1050px',
    backgroundColor: palette.secondary.greyLight,
    borderRadius: '12px',
    paddingTop: '24px',
  },

  '.std_box': {
    maxHeight: '64px',
    borderRadius: '12px',
    padding: '16px',
    border: '1px solid #313131',
    backgroundColor: '#252525',

    '&.selected': {
      backgroundColor: '#2F2F2F',
      border: `1px solid ${palette.primary.main}`,
    },
    '&.active': {
      backgroundColor: '#2F2F2F',
      border: `1px solid ${palette.whiteEmphasis.low}`,
    },
  },

  '.gateway_box': {
    maxWidth: '220px',
    justifyContent: 'space-between',
  },
  '.bru_box': {
    maxWidth: '190px',
    justifyContent: 'space-between',
  },

  '.sensor_box': {
    width: '100px',
    height: '33px',
    borderRadius: '36px',
    border: '1px solid #313131',
    backgroundColor: palette.background.main,
    padding: '5px',
    marginLeft: '8px',

    p: {
      fontSize: '12px',
      color: palette.whiteEmphasis.high,
    },

    '&.selected': {
      backgroundColor: palette.secondary.greyLight,
      border: `1px solid ${palette.primary.main}`,
    },
  },

  '.flex': {
    display: 'flex',
    alignItems: 'center',
  },

  '.block': {
    display: 'block',
  },

  '.full': {
    width: '100%',
  },

  '.around': {
    justifyContent: 'space-around',
  },

  '.flex_between': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.sticky': {
    position: 'sticky',
    top: 0,
    paddingBottom: '16px',
    backgroundColor: palette.secondary.greyLight,

    p: {
      fontSize: '11px',
      color: palette.whiteEmphasis.medium,
    },
  },

  '.sensor_name': {
    margin: '0 8px',
    display: 'inline',
  },
  '.device_title': {
    fontSize: '20px',
    color: palette.whiteEmphasis.high,
    display: 'inline',
    marginRight: '8px',
  },
  '.device_subtitle': {
    fontSize: '14px',
    color: palette.whiteEmphasis.medium,
    display: 'inline',
    marginRight: '8px',
  },

  '.close_sensor_btn': {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },

  '.icon_8': {
    width: '8px',
    height: '8px',
  },
  '.icon_16': {
    width: '16px',
    height: '16px',
    color: palette.whiteEmphasis.medium,
    '&.pointer': {
      cursor: 'pointer',
    },
  },
  '.icon_32': {
    width: '32px',
    height: '32px',
    marginRight: '16px',
  },
  '.icon_40': {
    width: '40px',
    height: '40px',
    marginRight: '16px',
  },

  '.red': {
    color: palette.status.red,
  },
  '.green': {
    color: palette.status.green,
  },
  '.tab_wrapper': {
    padding: '16px',
    backgroundColor: palette.background.main,
  },

  '.tab_content': {
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: palette.secondary.greyLight,
  },

  '.general_box': {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '360px',
    flexDirection: 'column',
  },

  '.connectivity_title': {
    fontSize: '20px',
  },

  '.item_box': {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    alignItems: 'start',
  },

  '.subtitle': {
    fontSize: '14px',
    color: palette.whiteEmphasis.high,
  },
  '.title': {
    fontSize: '11px',
    color: palette.whiteEmphasis.medium,
  },
  '.divider': {
    color: palette.whiteEmphasis.medium,
  },

  '.gateway_connection_type_box': {
    position: 'absolute',
    right: '50px',
  },
}));
