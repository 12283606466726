import { types } from 'mobx-state-tree';

export const ItemEvent = types.model({
  id: types.union(types.string, types.integer),
  // collection_schema: types.string,
  collection_name: types.maybeNull(types.string),
  // collection_element_id: types.integer,
  change_type: types.maybeNull(types.enumeration('change_type', ['I', 'U', 'D'])),
  changed_at: types.maybeNull(types.string),
  changed_by: types.maybeNull(types.integer),
  changed_keys: types.maybeNull(types.array(types.string)),
  change_before: types.maybeNull(types.map(types.frozen())),
  change_after: types.maybeNull(types.map(types.frozen())),
  operation: types.maybeNull(types.string),
  _beverages_name: types.maybeNull(types.string),
  _users_display_name_changed_by: types.maybeNull(types.string),

  item_id: types.maybeNull(types.integer),
  type_code: types.maybeNull(types.integer),
  happened_at: types.maybeNull(types.string),
  as_of: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  archived: types.optional(types.boolean, false),
  caused_by: types.maybeNull(types.integer),
});
